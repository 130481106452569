<template>
  <div class="position-relative">
    <h5>กรุณายืนยันรายการ</h5>
    <div class="mb-3">
      <table class="table table-bordered table-sm table-info">
        <thead class="bg-info">
          <tr class="text-center">
            <th>ประเภท</th>
            <th>ชุดเลข</th>
            <th>จำนวนชุด</th>
            <th>ยอดแทง</th>
            <th>ส่วนลด</th>
            <th>#</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, index) in items" :key="index" :class="[{'alert-danger':!row.isValid}, {'alert-warning':row.isWarning}]">
            <td class="text-center"><span>{{ row.text }}</span></td>
            <td class="text-center"><span class="text-primary">{{ row.number }}</span></td>
            <td class="text-center">{{ row.qty }}</td>
            <td class="text-right"><span>{{ row.amount | amountFormat(2) }}</span></td>
            <td class="text-right"><span>{{ row.discount | amountFormat(2) }}</span></td>
            <td class="text-center"><span class="btn-delete cursor-pointer" style="cursor: pointer;" @click="removeRow(index)"><i class="far fa-trash-alt text-danger bigger-175"></i></span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-if="invalidItems">
      <h6 class="text-danger">***แจ้งเตือน***</h6>
      <div class="mb-3">
        <table class="table table-bordered table-sm table-danger">
          <thead class="bg-danger">
            <tr class="text-center">
              <th width="25%">ประเภท</th>
              <th width="20%">ชุดเลข</th>
              <th>หมายเหตุ</th>
            </tr>
          </thead>
          <tbody>
            <tr class="alert-danger" v-for="(row, index) in invalidItems" :key="'invalid-'+index">
              <td class="text-center">{{ row.text }}</td>
              <td class="text-center">{{ row.number }}</td>
              <td class="text-center">{{ row.note }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <h5 class="text-center">
      ยอดเดิมพัน <span> {{ total.amount | amountFormat(2, '0.00') }} </span> บาท
    </h5>
    <h5 class="text-center">
      ส่วนลด <span class="text-danger"> {{ total.discount | amountFormat(2, '0.00') }} </span> บาท
    </h5>
    <h5 class="text-center">
      รวม <span class="text-primary"> {{ total.credit | amountFormat(2, '0.00') }} </span> บาท
    </h5>
    <p class="text-center">หมายเหตุ: {{ ticket.remarks }}</p>

    <div class="row justify-content-center">
      <div class="col-3 pr-1">
        <button class="btn btn-block btn-danger" type="button" @click="cancel">ยกเลิก</button>
      </div>
      <div class="col-3 px-1">
        <button class="btn btn-block btn-dark" type="button" @click="back">ย้อนกลับ</button>
      </div>
      <div class="col-3 pl-1">
        <button class="btn btn-primary btn-block" type="button" @click="confirm">ยืนยัน</button>
      </div>
    </div>

    <loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="false"
      background-color="#EEEEEE"
      :height="60"
      :width="60"
      color="#51CE8D"
      loader="dots"
    />
  </div>
</template>
<script>
import moment from '@/helpers/moment'
import Swal from 'sweetalert2'
import LotteryService from '@/services/LotteryService'
import cAlert from '@/helpers/alert'

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  name: 'LottosetConfirm',
  components: {
    Loading
  },
  props: ['round', 'ticket'],
  data() {
    return {
      isLoading: false,
      invalidItems: null
    }
  },
  computed: {
    balance() {
      return this.$store.state.account.balance || 0
    },
    items() {
      return this.ticket.rows.map((row)=>{
        const amount = row.qty * row.price
        const discount = row.qty * this.round.lottoset.discount
        const item = {
          ...row,
          type: 'lottoset',
          text: 'เลขชุด',
          amount: amount,
          discount: discount,
          credit: (amount-discount),
          isValid: true,
          isWarning: false
        }
        return item
      })
    },
    total() {
      const total = this.items.reduce((total, row)=>{
        total.amount += row.amount
        total.discount += row.discount
        total.credit += row.credit
        return total
      }, {
        amount: 0,
        discount: 0,
        credit: 0
      })
      return total
    }
  },
  methods: {
    removeRow(index) {
      this.ticket.rows.splice(index, 1)
    },
    cancel() {
      this.$emit('cancel')
    },
    back() {
      this.$emit('back')
    },
    confirm() {

      /**
       * ไม่มีรายการแทง
       */
      if(this.ticket.rows.length === 0) {
        return Swal.fire({
          text: 'ไม่มีรายการแทงเลข',
          icon: 'warning',
          confirmButtonText: 'OK'
        })
      }

      /**
       * ตรวจสอบยอดเดิมพัน
       */
      const invalidRows = this.items.filter(row=>!row.isValid)
      if(invalidRows.length > 0) {
        return Swal.fire({
          text: 'กรุณาตรวจสอบรายการเดิมพันให้ถูกต้อง',
          icon: 'warning',
          confirmButtonText: 'OK'
        })
      }

      /**
       * ตรวจสอบยอดเงินในบัญชี
       */
      if(this.total.credit > this.balance) {
        const balance = this.balance.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        this.$store.dispatch('reloadBalance')
        return Swal.fire({
          title: 'ยอดเงินในบัญชีไม่พอ',
          text: `ยอดเงินคงเหลือ ${balance}`,
          icon: 'warning',
          confirmButtonText: 'OK'
        })
      }

      this.isLoading = true
      this.invalidItems = null
      LotteryService.createLottosetTicket(this.ticket)
      .then((response)=>{
        if(response.success) {
          this.$emit('success')
        }else{
          throw new Error(response)
        }
      })
      .catch((e)=>{
        cAlert({
          ...e,
          title: 'ผิดพลาด!',
          text: e?.message || 'ส่งโพยไม่สำเร็จ',
          icon: 'error',
          confirmButtonText: 'OK'
        }, ()=>{
          /**
           * error code 501 = ให้ redirect ไปหน้ารวมหวย
           */
          if(e?.status === 501) {
            this.$router.push({name: 'Lottosets'})
          }
        })

        /**
         * รายการเลขไม่ถูกต้อง
         */
        if(e?.name === 'TicketItemException') {
          this.invalidItems = e?.errors[0].data
        }
      })
      .finally(()=>{
        this.isLoading = false
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.table {
  font-size: 90%;
  background-color: #FFF;

  thead {
    th {
      font-weight: normal !important;
    }
  }

  tbody {
    tr {
      td {
        vertical-align: middle;
      }
    }
  }
}
</style>
